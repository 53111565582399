import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

const Censo = props => {
    const [imgFrontal, setImgFrontal] = useState(null);
    const [imgLateral, setImgLateral] = useState(null);
    const [imgLateral2, setImgLateral2] = useState(null);
    const [huellaDactilar, setHuellaDactilar] = useState(null);
    const AddAttachments = (e, objId) => {
        console.log("FILES", e.target.files);
        var theFiles = Array.from(e.target.files);

        theFiles.forEach(async item => {
            var reader = new FileReader();



            reader.onload = function (event) {
                switch (objId) {
                    case "imgFrontal":
                        setImgFrontal(event.target.result);
                        break;
                    case "imgLateral":
                        setImgLateral(event.target.result);
                        break;
                    case "imgLateral2":
                        setImgLateral2(event.target.result);
                        break;
                    case "huellaDactilar":
                        setHuellaDactilar(event.target.result);
                        break;
                    default:
                        break;
                }

            };


            reader.readAsDataURL(item);

            debugger;


        });
    };

    return (<>
        <Row className="mx-0">
            <Col xs={12} md={10} className="mx-auto my-4">
                <label className="display-2">
                    Censo <small>- 2023</small>
                </label> 
                {/* GENERAL */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header">
                        <span className="display-3">Datos personales</span>
                    </div>
                    <div className="card-body">
                        <Row>
                            <Col xs={3} md={6} className="mt-2">
                                <label>Primer Nombre</label>
                                <input type="text" className="form-control"></input>
                            </Col>
                            <Col xs={3} md={6} className="mt-2">
                                <label>Segundo Nombre</label>
                                <input type="text" className="form-control"></input>
                            </Col>
                            <Col xs={3} md={6} className="mt-2">
                                <label>Apellido</label>
                                <input type="text" className="form-control"></input>
                            </Col>
                            <Col xs={3} md={6} className="mt-2">
                                <label>Segundo Apellido</label>
                                <input type="text" className="form-control"></input>
                            </Col>
                            <Col xs={3} className="mt-2">
                                <label>Edad</label>
                                <input type="text" className="form-control"></input>
                            </Col>
                            <Col xs={3} className="mt-2">
                                <label>Fecha de Nacimiento</label>
                                <input type="date" className="form-control"></input>
                            </Col>
                            <Col xs={6} className="mt-2">
                                <label>Tipo de Sangre</label>
                                <input type="text" className="form-control"></input>
                            </Col>
                            <Col xs={4}>
                                <label>Tipo Documento</label>
                                <select className="form-control">
                                    <option value="">-</option>
                                    <option value="cedula">Cédula</option>
                                    <option value="pasaporte">Pasaporte</option>
                                    <option value="otro">Otro</option>
                                </select>

                            </Col>
                            <Col xs={8}>
                                <label>Número de documento de identidad</label>
                                <input type="text" className="form-control"></input>
                            </Col>
                           

                            <Col xs={6} className="mt-2">
                                <label>7. Género</label>
                                <select className="form-control">
                                    <option value="">-</option>
                                    <option value="masculino">Masculino</option>
                                    <option value="femenino">Femenino</option>

                                </select>

                            </Col>
                            <Col xs={6} className="mt-2">
                                <label>10. Nacionalidad</label>
                                <select className="form-control">
                                    <option value="">-</option>
                                    <option value="haitiana">Haitiana</option>
                                    <option value="dominicana">Dominicana</option>
                                    <option value="otra">Otra</option>

                                </select>

                            </Col>

                        </Row>
                    </div>
                </div>
                {/* LABORAL */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header">
                        <span className="display-3">Datos Laborales</span>
                    </div>
                    <div className="card-body">
                        <Row>

                            <Col xs={8} className="mt-2">
                                <label>Empresa</label>
                                <select className="form-control">
                                    <option value="">-</option>
                                </select>
                            </Col>
                            <Col xs={4} className="mt-2">
                                <label>Tiempo laborando</label>
                                <input type="text" className="form-control"></input>
                            </Col>
                            <Col xs={4} className="mt-2">
                                <label>Código Empleado/Carnet</label>
                                <input type="text" className="form-control"></input>
                            </Col>
                            <Col xs={8} className="mt-2">
                                <label>Cúal es su función en la empresa?</label>
                                <input type="text" className="form-control"></input>
                            </Col>
                         
                        </Row>
                    </div>
                </div>
                {/* FAMILIA */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header">

                        <span className="display-3">Familia</span>
                    </div>
                    <div className="card-body">
                        <Row>
                            <Col xs={6} className="mt-2">
                                <label>Estado Civil</label>
                                <select className="form-control">
                                    <option value="">-</option>
                                    <option value="casado">Casado</option>
                                    <option value="soltero">Soltero</option>
                                    <option value="unionlibre">Unión Libre</option>
                                    <option value="otro">Otro</option>
                                </select>

                            </Col>


                            <Col xs={6} className="mt-2">
                                <label>Nombre Conyugue</label>
                                <input type="text" className="form-control"></input>
                            </Col>
                            {/* <Col xs={6} className="mt-2">
                                <label>Nacionalidad Conyugue</label>
                                <select className="form-control">
                                    <option value="">-</option>
                                    <option value="haitiana">Haitiana</option>
                                    <option value="dominicana">Dominicana</option>
                                    <option value="otro">Otro</option>
                                </select>

                            </Col> */}
                            <Col xs={6} className="mt-2">
                                <label>Hijos</label>
                                <Row>
                                    <Col>
                                        <div className="input-group">
                                            <span className="input-group-text">
                                                Hembras
                                            </span>
                                            <input type="text" className="form-control"></input>
                                        </div>
                                    </Col>

                                    <Col>
                                        <div className="input-group">
                                            <span className="input-group-text">
                                                Varones
                                            </span>
                                            <input type="text" className="form-control"></input>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/* DEPENDIENTES */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header">

                        <span className="display-3">Dependientes</span>
                    </div>
                    <div className="card-body">
                        <Row>
                            {/* <Col xs={6} className="mt-2">
                                <label>Con cuantas personas vive?</label>
                                <input type="number" className="form-control" />
                            </Col> */}
                            <Col xs={6} className="mt-2">
                                <label>Cúantas personas dependen de usted?</label>
                                <input type="number" className="form-control" />
                            </Col>
                            {/* <Col xs={12} className="mt-2">
                                <label>Cuántos de ellos trabajan?</label>
                                <input type="number" className="form-control" />
                            </Col>
                            <Col xs={12} className="mt-2">
                                <label>Cuántos dependientes son envejecientes?</label>
                                <input type="number" className="form-control" />
                            </Col>
                            <Col xs={12} className="mt-2">
                                <label>Cuántos dependientes son menores de edad?</label>
                                <input type="number" className="form-control" />
                            </Col> */}
                        </Row>
                    </div>
                </div>
                {/* VIVIENDA */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header">

                        <span className="display-3">Vivienda</span>
                    </div>
                    <div className="card-body">
                        <Row>
                            <Col xs={6} className="mt-2">
                                <label>Estatus Vivienda </label>
                                <select className="form-control">
                                    <option value="">-</option>
                                    <option value="propia">Propia</option>
                                    <option value="alquilada">Alquilada</option>

                                </select>

                            </Col>
                            <Col xs={6} className="mt-2">
                                <label>Tipo de Vivienda </label>
                                <select className="form-control">
                                    <option value="">-</option>
                                    <option value="Madera y Zinc">Madera y Zinc</option>
                                    <option value="Blocks y Zinc">Blocks y Zinc</option>
                                    <option value="Blocks y hormigon">Blocks y Hormigon</option>
                                    <option value="otro">Otro</option>

                                </select>

                            </Col>

                            <Col xs={12} className="mt-2">
                                <label>Donde vive?</label>
                                <Row>
                                    {/* <Col>
                                        <div className="input-group">
                                            <span className="input-group-text">
                                                Sector
                                            </span>
                                            <input type="text" className="form-control"></input>
                                        </div>
                                    </Col> */}

                                    <Col>
                                        <div className="input-group">
                                            <span className="input-group-text">
                                                Ciudad
                                            </span>
                                            <input type="text" className="form-control"></input>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                           
                        </Row>
                    </div>
                </div>
                {/* AGUA */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header">

                        <span className="display-3">Agua</span>
                    </div>
                    <div className="card-body">
                        <Row>
                            <Col xs={6} className="text-truncate">
                                <label >Tiene agua de acueducto en su hogar?</label>
                                <select className="form-control">
                                    <option value="">-</option>
                                    <option value="true">Si</option>
                                    <option value="false">No</option>
                                </select>
                            </Col>
                            <Col xs={6} >
                                <label>Qué agua toma?</label>
                                <select className="form-control">
                                    <option value="">-</option>
                                    <option value="potable">Potable (Botellones, etc)</option>
                                    <option value="llave">Llave</option>
                                    <option value="pozo">Pozo</option>
                                    <option value="rio">Rio</option>
                                    <option value="otra">Otra</option>
                                </select>
                            </Col>
                           
                        </Row>
                    </div>
                </div>
                {/* ELECTRICIDAD */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header">

                        <span className="display-3">Electricidad</span>
                    </div>
                    <div className="card-body">
                        <Row>
                            <Col xs={6} className="mt-2">
                                <label>Tiene electricidad en su hogar?</label>
                                <select className="form-control">
                                    <option value="">-</option>
                                    <option value="true">Si</option>
                                    <option value="false">No</option>
                                </select>
                            </Col>
                           

                        </Row></div>
                </div>
                {/* Transporte */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header">

                        <span className="display-3">Transporte</span>
                    </div>
                    <div className="card-body">
                        <Row>
                            <Col xs={12} className="mt-2">
                                <label>Medio de transporte</label>
                                <select className="form-control">
                                    <option value="">-</option>
                                    <option value="apies">A pies</option>
                                    <option value="bicicleta">Bicicleta</option>
                                    <option value="motocicleta">Motocicleta</option>
                                    <option value="automovil">Automóvil</option>
                                    <option value="publico">Público</option>
                                </select>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/* Preparacion Academica */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header">

                        <span className="display-3">Preparacion academica</span>
                    </div>
                    <div className="card-body">
                        <Row>
                            <Col xs={6} className="mt-2">
                                <label>Preparación Académica </label>
                                <select className="form-control">
                                    <option value="">-</option>
                                    <option value="ninguna">Ninguna</option>
                                    <option value="primaria">Primaria</option>
                                    <option value="secundaria">Secundaria</option>
                                    <option value="tecnica">Técnica</option>
                                    <option value="universitaria">Universitaria</option>
                                    <option value="otra">Otra</option>

                                </select>

                            </Col>
                            <Col xs={6} className="mt-2">
                                <label>Cuántos hijos asisten a la escuela?</label>
                                <input type="number" className="form-control"></input>

                            </Col>
                        </Row>
                    </div>
                </div>
                {/* idioma */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header">
                        <span className="display-3">Idioma</span>
                    </div>
                    <div className="card-body">
                        <Row>

                            <Col xs={12} className="mt-2">
                                <label>Qué idiomas habla?</label>
                                <Row>
                                    <Col>
                                        <div className="input-group">
                                            <label>
                                                Creole
                                            </label>
                                            <input type="checkbox" className="form-check-input mx-2"></input>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="input-group">
                                            <label>
                                                Francés
                                            </label>
                                            <input type="checkbox" className="form-check-input mx-2"></input>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="input-group">
                                            <label>
                                                Inglés
                                            </label>
                                            <input type="checkbox" className="form-check-input mx-2"></input>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="input-group">
                                            <label>
                                                Español
                                            </label>
                                            <input type="checkbox" className="form-check-input mx-2"></input>
                                        </div>
                                    </Col>
                                </Row>

                            </Col>

                            <Col xs={12} className="mt-2">
                                <label>Qué idiomas escribe?</label>
                                <Row>
                                    <Col>
                                        <div className="input-group">
                                            <label>
                                                Creole
                                            </label>
                                            <input type="checkbox" className="form-check-input mx-2"></input>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="input-group">
                                            <label>
                                                Francés
                                            </label>
                                            <input type="checkbox" className="form-check-input mx-2"></input>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="input-group">
                                            <label>
                                                Inglés
                                            </label>
                                            <input type="checkbox" className="form-check-input mx-2"></input>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="input-group">
                                            <label>
                                                Español
                                            </label>
                                            <input type="checkbox" className="form-check-input mx-2"></input>
                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </div>
                </div>
                {/* ALIMENTOS */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header">
                        <span className="display-3">Alimentos Cocidos</span>
                    </div>
                    <div className="card-body">
                        <Row>
                            
                            <Col  className="mt-2">
                                <label>Con qué cocina los alimentos?</label>
                                <select className="form-control">
                                    <option value="">-</option>
                                    <option value="leña">Leña</option>
                                    <option value="Carbón">Carbón</option>
                                    <option value="Estufa">Estufa GLP</option>
                                    <option value="otros">Otros</option>

                                </select>

                            </Col>
                        </Row>
                    </div>
                </div>
                {/* Comunicación */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header">
                        <span className="display-3">Comunicación</span>
                    </div>
                    <div className="card-body">
                        <Row>
                            <Col xs={6} className="mt-2">
                                <label>Tiene telefono de celular</label>
                                <select className="form-control">
                                    <option value="">-</option>
                                    <option value="true">Si</option>
                                    <option value="false">No</option>

                                </select>

                            </Col>
                            <Col xs={6} className="mt-2">
                                <label>Compañia Telefónica que usa</label>
                                <select className="form-control">
                                    <option value="">-</option>
                                    <option value="digicel">Digicel</option>
                                    <option value="natcom">Natcom</option>
                                    <option value="otra">Otra</option>

                                </select>

                            </Col>
                          
                        </Row>
                    </div>
                </div>
                {/* Finanzas */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header">
                        <span className="display-3">Finanzas</span>
                    </div>
                    <div className="card-body">
                        <Row>
                            <Col xs={12} className="mt-2">
                                <label>De que entidad bancaria tiene cuenta?</label>
                                <Row>
                                    <Col>
                                        <select className="form-control">
                                            <option value="">-</option>
                                            <option value="bnc">BNC</option>
                                            <option value="buh">BUH</option>
                                            <option value="fonkoze">Fonkoze</option>
                                            <option value="otro">Otro</option>
                                            <option value="sogebank">Sogebank</option>
                                            <option value="unibank">Unibank</option>
                                            <option value="none">No tengo cuenta bancaria</option>
                                        </select>
                                    </Col>
                                    
                                </Row>
                            </Col>

                        </Row>
                    </div>
                </div>
                {/* Imagenes */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header">
                        <span className="display-3">Imagen</span>
                    </div>
                    <div className="card-body">
                        <Row>

                            <Col xs={12} className="mt-2">
                                <label>Imagenes de perfil</label>
                                <Row>
                                    <Col>
                                        <label>Frontal</label><br />
                                        <button className="btn btn-file btn-secondary" type="button">
                                            <i className='fa fa-upload mx-2'></i>
                                            Subir Archivo
                                            <input
                                                className=""
                                                onChange={e => {
                                                    AddAttachments(e, "imgFrontal");
                                                }}
                                                id="inputFiles"
                                                type="file"

                                            />
                                        </button>
                                        <img src={imgFrontal} className="img-fluid" />

                                    </Col>
                                    {/* <Col>
                                        <label>Foto 02</label><br />
                                        <button className="btn btn-file btn-secondary" type="button">
                                            <i className='fa fa-upload mx-2'></i>
                                            Subir Archivo
                                            <input
                                                className=""
                                                onChange={e => {
                                                    AddAttachments(e, "imgLateral");
                                                }}

                                                type="file"

                                            />
                                        </button>
                                        <img src={imgLateral} className="img-fluid" />

                                    </Col>
                                    <Col>
                                        <label>Foto 03</label><br />
                                        <button className="btn btn-file btn-secondary" type="button">
                                            <i className='fa fa-upload mx-2'></i>
                                            Subir Archivo
                                            <input
                                                className=""
                                                onChange={e => {
                                                    AddAttachments(e, "imgLateral2");
                                                }}

                                                type="file"

                                            />
                                        </button>
                                        <img src={imgLateral2} className="img-fluid" />

                                    </Col> */}
                                    <Col>
                                        <label>Huella Dactilar</label><br />
                                        <button className="btn btn-file btn-secondary" type="button">
                                            <i className='fa fa-upload mx-2'></i>
                                            Subir Archivo
                                            <input
                                                className=""
                                                onChange={e => {
                                                    AddAttachments(e, "huellaDactilar");
                                                }}

                                                type="file"

                                            />
                                        </button>
                                        <img src={huellaDactilar} className="img-fluid" />

                                    </Col>
                                </Row>


                            </Col>
                        </Row >
                    </div>
                </div >
                <p className=" text-center">
                    <button className="btn btn-primary btn-block">Guardar</button>
                </p>

            </Col >
        </Row >
    </>)
}

export default Censo;