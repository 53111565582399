//We are settings an initial state here for clarity, probably is not needed

const Settings = {
    App: "VO",
    Name: "VO",

    baseURl: "./",
    //    BasePathForAPI: "https://app.pleelo.com/api/",//
    //BasePathForAPI: "https://vo.shearly.com/api/",//local on shearly
    BasePathForAPI: "https://voapi.shearly.com/api/",



};

export default Settings;
