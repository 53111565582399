
export const Routes = {
    // pages
    Home : {path:"/#"},
    Dashboard: { path: "/Dashboard" },
    
    
    Settings: {path:"/settings"},
    
    NotFound: { path: "/examples/NotFound" },
    ServerError: { path: "/examples/ServerError" },
    
    Profile: {path:"/profile"},
    SignIn: {path:"/signin"},
    Register: {path:"/Register"},
    ForgotPassword: {path: "/ForgotPassword"},
    ResetPassword: {path: "/ResetPassword"},
    
    Users: {path: "/Users"},

    Censo: {path: "/censo"},
    People: {path: "/people"},
    Carnet: {path: "/carnet"}
};