import React, { useEffect, useState } from 'react';

import { Modal, Button, Col, Row, Form } from '@themesberg/react-bootstrap';

import * as yup from 'yup';
import { Formik, useFormik } from 'formik';

import API from '../../common/API';
import { parsedUser } from '../../common/GetCurrentUser';

//Validation
const validationSchema = yup.object({
    password: yup.string().required(),
    
    //  phone: yup.string().required(),

});
//Component
const AddEditPassword = props => {
    const [model, setModel] = useState({
        id: props.model.id,
        currentUserId: ''
    });
    const [state, setState] = useState({

        UI: {
            isLoading: false
        },
        Roles: []

    })
    useEffect(() => {
        console.log("the props", props)
        setModel({
            ...model, 
            password: ""
        });
    }, [])

    const user = parsedUser();
  
    const handleSubmit = async obj => {
        try {
            
            console.log("values to submit", obj);
            let path = "Users/AddEditUserPassword";
            obj.id = user.ID;
            
            setState({
                ...state,
                UI:{
                    ...state.UI,
                    isLoading: !state.UI.isLoading
                }
            })
            
            let request = await API.postAction(path, obj);
            setState({
                ...state,
                UI:{
                    ...state.UI,
                    isLoading: !state.UI.isLoading
                }
            })
            
            if (request.status === 200) {
                props.toggle(request.data, true);
            }
        } catch (ex) {
            console.error(ex);
            setState({
                ...state,
                UI:{
                    ...state.UI,
                    isLoading: false
                }
            })
            
        }

    };

    const formik = useFormik({
        initialValues: model ,
        validationSchema,
        onSubmit: handleSubmit,
    });


    return (
        <Modal show={props.show} onHide={props.toggle} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>Usuario</Modal.Title>
            </Modal.Header>

            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Row>
                   
                        <Col xs={12} className="mt-2">
                            <Form.Label>Clave</Form.Label>
                            <br/>
                            <small className="text-muted">Nueva contraseña</small>
                            <Form.Control type="text"
                                id="password"
                                value={formik.values.password} onChange={formik.handleChange} />
                            {formik.errors.password ? <div className="invalid text-sm">{formik.errors.password}</div> : null}

                        </Col>


                    </Row>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" type="submit" className="btn-sm"
                        disabled={state.UI.isLoading}
                    >
                        Guardar
                    </Button>
                </Modal.Footer>
            </Form>


        </Modal>
    )
}

export default AddEditPassword;