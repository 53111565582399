import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "./routes";

// pages



import Settings from './components/Settings/Settings';
// components
import Sidebar from "./components/Common/Sidebar";
import Navbar from "./components/Common/Navbar";
import Footer from "./components/Common/Footer";
import Preloader from "./components/Preloader";
import Censo from './pages/censo';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>)} />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {

    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);


  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        
        <Sidebar  />

        <main className="content ">
          <div className="row mx-0">
            <div className="contentContainer">
              <Navbar {...props} />
              <Component {...props} />

              <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
            </div>

          </div>
        </main>

      </>
    )}
    />
  );
};

export default () => {

  return (

    
      <Switch>
        {/* pages */}
        <Route exact path={Routes.Censo.path} component={Censo} />
        <Route exact path={"/"} component={Censo} />
        {/* <Route exact path={Routes.AppointmentDetails.path} component={ActivityDetails} /> */}
        <Redirect to={Routes.NotFound.path} />
      </Switch>

    
  )
};
